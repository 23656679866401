import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 purple-bg">
                            <div className="footer-copyright text-white">Copyright &copy; <span
                                id="copyright"> 2020 </span>
                                <a className="text-white" href="/">Caglar Ergul.</a></div>
                        </div>
                        <div className="col-md-9">
                            <ul className="follow-us pull-right">
                                <li>Follow Me :</li>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <li><a href="https://www.github.com/caglarergul" target={"_blank"}><i className="fa fa-github"/></a></li>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <li><a href="https://www.linkedin.com/in/caglarergul" target={"_blank"}><i className="fa fa-linkedin"/></a></li>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <li><a href="https://www.facebook.com/cagglar" target={"_blank"}><i className="fa fa-facebook"/></a></li>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <li><a href="https://www.twitter.com/caglarergul" target={"_blank"}><i className="fa fa-twitter"/></a></li>
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <li><a href="https://www.instagram.com/caglarergul" target={"_blank"}><i className="fa fa-instagram"/></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
