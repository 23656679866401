import React, {Component} from 'react';

class Home extends Component {
    componentDidMount () {
        const script = document.createElement("script");

        script.src = "/js/custom.js";
        script.id = "customJS";
        script.async = true;

        document.body.appendChild(script);
    }

    componentWillUnmount() {
        let customJS = document.getElementById("customJS");
        let parentOfCustom = customJS.parentElement;
        parentOfCustom.removeChild(customJS);
    }


    render() {
        return (
            <div className="main-container">

                <section className="me-home wrapper">
                    <div className="container-fluid h-100">
                        <div className="row row-eq-height h-100">
                            <div className="col-md-3 purple-bg">
                                <div className="iq-objects">
                            <span className="iq-objects-01">
                                    <img className="img-fluid" src="images/01.png" alt="drive" />
                                </span>
                                </div>
                            </div>
                            <div className="col-md-9 main-content align-self-center">
                                <div id="boxscroll" className="iq-home">
                                    <div className="home-deta iq-mt-90">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="boder-img">
                                                    <img src="images/caglarergul_profile.jpg" className="img-fluid" alt="#" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 content-deta">
                                                <h2 className="iq-tw-8 ">Hello,<br />I am Caglar Ergul</h2>
                                                <div id="typer"></div>
                                                <p className="lead iq-tw-4">I have almost ten years of experience working in web technologies.<br />
                                                    Currently, I am working on Optimum7 as a Frontend Engineer, and the company has a vision about
                                                    current and further technology development. And also I want to stay up to date about my software development skills.
                                                    <br /><br />
                                                    Previously, I worked as a Frontend Engineer on a company which is producing travel based web
                                                    and mobile applications.
                                                    <br /><br />
                                                    <strong>Competencies:</strong> HTML / CSS / JavaScript / React, Redux, Angular, (Big Commerce, Shopify) E-Commerce Development,
                                                    Wordpress Development, Amazon Web Services, NodeJS, RestAPIs, ExpressJS. </p>
                                                <a className="button iq-mt-30" href="/caglar_ergul_resume.zip">Download my CV</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default Home;
