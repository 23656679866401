import React, {Component} from 'react';

class About extends Component {
    componentDidMount () {
        const script = document.createElement("script");

        script.src = "/js/custom.js";
        script.id = "customJS";
        script.async = true;

        document.body.appendChild(script);
    }

    componentWillUnmount() {
        let customJS = document.getElementById("customJS");
        let parentOfCustom = customJS.parentElement;
        parentOfCustom.removeChild(customJS);
    }


    render() {
        return (
            <div className="main-container">

                <section className="me-about wrapper">
                    <div className="container-fluid h-100">
                        <div className="row row-eq-height h-100">
                            <div className="col-md-3 purple-bg">
                                <div className="iq-objects">
                            <span className="iq-objects-01">
                                    <img className="img-fluid" src="images/01.png" alt="drive" />
                                </span>
                                </div>
                            </div>
                            <div className="col-md-9 main-content align-self-center">
                                <div id="boxscroll" className="iq-home about-us">
                                    <div className="home-deta iq-mt-80">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="boder-img">
                                                    <img src="images/me2.jpg" className="img-fluid" alt="#" />
                                                </div>
                                            </div>
                                            <div className="col-md-8 content-deta">
                                                <div className="main-title">
                                                    <small>Know About Me</small>
                                                    <h1 className="display-4 iq-tw-8 iq-font-purple">About Me</h1>
                                                </div>
                                                <p className="iq-tw-4 iq-mt-10 iq-mb-20">Hello! I’m Caglar.</p>
                                                <ul className="contact-info">
                                                    <li><span className="iq-tw-6">Name :</span> Çağlar ERGÜL</li>
                                                    <li><span className="iq-tw-6">Date of birth :</span> 09-10-1990</li>
                                                    <li><span className="iq-tw-6">Email :</span> <a href="#"><span
                                                        className="__cf_email__">caglarergulcom[at]gmail.com</span></a>
                                                    </li>
                                                    <li><span className="iq-tw-6">Address :</span> Izmir, Turkey
                                                    </li>
                                                    <li><span className="iq-tw-6">Nationality :</span> Turkish
                                                    </li>
                                                    <li><span className="iq-tw-6">Gender :</span> Male</li>
                                                    <li><span className="iq-tw-6">Marital Status :</span> Single</li>
                                                    <li><span className="iq-tw-6">Currently Working :</span> Optimum7</li>
                                                    <li><span className="iq-tw-6">Work Status :</span> Frontend Engineer</li>
                                                </ul>
                                                <a className="button" href="/resume">resume</a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default About;
