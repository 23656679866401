import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Resume from "./components/Resume";
import Contact from "./components/Contact";
import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";

class App extends Component {
    render() {
        return (
            <Router>
                <Header/>
                <Switch>
                    <Route path="/about">
                        <About/>
                    </Route>
                    <Route path="/resume">
                        <Resume/>
                    </Route>
                    <Route path="/contact">
                        <Contact/>
                    </Route>
                    <Route path="/">
                        <Home/>
                    </Route>
                </Switch>
                <Footer/>
            </Router>
        );
    }
}

export default App;
