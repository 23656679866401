import React, {Component} from 'react';
import emailjs from 'emailjs-com';
import SweetAlert from 'sweetalert2-react';

class Contact extends Component {


    constructor(props, context) {
        super(props, context);
        this.state = {
            showAlertError: false,
            showAlertSuccess: false
        };
    }

    componentDidMount () {
        const script = document.createElement("script");

        script.src = "/js/custom.js";
        script.id = "customJS";
        script.async = true;

        document.body.appendChild(script);
    }
    componentWillUnmount() {
        let customJS = document.getElementById("customJS");
        let parentOfCustom = customJS.parentElement;
        parentOfCustom.removeChild(customJS);
    }

    sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('gmail', 'contactform', e.target, 'user_oa5mgGkGeHyEBeO1kyD4H')
            .then((result) => {
                console.log(result.text);
                this.setState({showAlertSuccess: true});
            }, (error) => {
                console.log(error.text);
                this.setState({showAlertError: true});

            });
    }

    render() {
        return (
            <div>
            <SweetAlert
                show={this.state.showAlertSuccess}
                icon={"success"}
                title="Successful"
                text="Your contact message has been sent, Thanks for contacting me!"
                onConfirm={() => this.setState({ showAlertSuccess: false })}
            />
                <SweetAlert
                    show={this.state.showAlertError}
                    icon={"error"}
                    title="Failed"
                    text="Something is went wrong, Please try again or send email to caglarergulcom@gmail.com"
                    onConfirm={() => this.setState({ showAlertError: false })}
                />
            <div className="main-container">

                <section className="iq-contact wrapper">
                    <div className="container-fluid h-100">
                        <div className="row row-eq-height h-100">
                            <div className="col-md-3 purple-bg">
                                <div className="iq-objects">
                        <span className="iq-objects-01">
                            <img className="img-fluid" src="images/01.png" alt="drive" />
                        </span>
                                </div>
                            </div>
                            <div className="col-md-9 main-content align-self-center">
                                <div id="boxscroll" className="inner-box">
                                    <div className="iq-plr-40">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="main-title iq-mb-15">
                                                    <small>My Contact</small>
                                                    <h1 className="display-4 iq-tw-8 iq-font-purple">Contact Me</h1>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d200066.14126404357!2d26.93963446626482!3d38.4175916780687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd862a762cacd%3A0x628cbba1a59ce8fe!2zxLB6bWly!5e0!3m2!1str!2str!4v1589131497189!5m2!1str!2str"
                                                    style={{'border':'0'}} allowFullScreen />

                                            </div>
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h3 className="title iq-tw-8 text-uppercase iq-mt-0">Get in
                                                            Touch</h3>
                                                        <ul className="contact-info">
                                                            <li><i className="ti-location-pin"></i> <p>Izmir, Turkey</p></li>
                                                            <li><i className="ti-marker-alt"></i> <p> caglarergulcom[at]gmail.com
                                                            </p></li>
                                                            <li><i className="ti-linkedin"></i> <p> <a href={"https://www.linkedin.com/in/caglarergul/"}>Caglar ERGUL</a> </p>
                                                            </li>
                                                            <li><i className="ti-instagram"></i> <p><a href={"https://www.instagram.com/caglarergul/"}>@caglarergul</a> </p>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="iq-get-in">
                                                    <div id="formmessage">Success/Error Message Goes Here</div>


                                                        <form className="form-horizontal" id="contactform" onSubmit={this.sendEmail}>
                                                        <div className="contact-form row">
                                                            <div className="section-field col-md-4">
                                                                <input id="name" type="text" placeholder="Name*"
                                                                       name="name" />
                                                            </div>
                                                            <div className="section-field col-md-4">
                                                                <input type="email" placeholder="Email*" name="email" />
                                                            </div>
                                                            <div className="section-field col-md-4">
                                                                <input type="text" placeholder="Phone*" name="phone" />
                                                            </div>
                                                            <div className="section-field textarea col-md-12">
                                                                <textarea className="input-message"
                                                                          placeholder="Comment*" rows="5"
                                                                          name="message"/>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <input type="hidden" name="action" value="sendEmail"/>
                                                                <button id="submit" name="submit" type="submit"
                                                                        value="Send" className="button iq-mt-30">Send
                                                                    Message
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <div id="ajaxloader" style={{"display":"none"}}><img
                                                        className="center-block mt-30 mb-30"
                                                        src="images/ajax-loader.html" alt="#" /></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </div>
        );
    }
}

export default Contact;
