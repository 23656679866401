import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";

class Header extends Component {
    getNavLinkClass = path => {
        return this.props.location.pathname === path
            ? "nav-item active"
            : "nav-item";
    };
    render() {
        return (
            <div>
                <div id="loading" className="purple-bg">
                    <div id="loading-center">
                        <div data-loader="spinner"/>
                    </div>
                </div>

                <header className="header">
                    <div className="container-fluid">
                        <div className="row">
                            <nav className="navbar fixed-top navbar-expand-lg navbar-light white-bg">
                                <div className="col-md-3 purple-bg">
                                    <Link to="/" className="navbar-brand"><img src="images/logo.png"
                                                                               className="img-fluid"
                                                                               alt="logo"/></Link>
                                </div>
                                <div className="col-md-9">
                                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                                            data-target="#navbarSupportedContent"
                                            aria-controls="navbarSupportedContent"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="ti-menu"/>
                                    </button>
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        <ul className="navbar-nav mr-auto w-100 justify-content-end">
                                            <li className={this.getNavLinkClass("/")}>
                                                <Link to="/" className="nav-link"
                                                      data-type="page-transition">Home</Link>
                                            </li>
                                            <li className={this.getNavLinkClass("/about")}>
                                                <Link to="/about" className="nav-link" data-type="page-transition">About
                                                    me</Link>
                                            </li>
                                            <li className={this.getNavLinkClass("/resume")}>
                                                <Link to="/resume" className="nav-link"
                                                      data-type="page-transition">resume</Link>
                                            </li>

                                            <li className={this.getNavLinkClass("/github")}>
                                                <a href="https://github.com/caglarergul" target="_blank" className="nav-link"
                                                      data-type="page-transition">github</a>
                                            </li>

                                            <li className={this.getNavLinkClass("/contact")}>
                                                <Link to="/contact" className="nav-link"
                                                      data-type="page-transition">contact</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>

            </div>
        );
    }
}

export default withRouter(Header);
