import React, {Component} from 'react';

class Resume extends Component {
    componentDidMount () {
        const script = document.createElement("script");

        script.src = "/js/custom.js";
        script.id = "customJS";
        script.async = true;

        document.body.appendChild(script);
    }
    componentWillUnmount() {
        let customJS = document.getElementById("customJS");
        let parentOfCustom = customJS.parentElement;
        parentOfCustom.removeChild(customJS);
    }


    render() {
        return (
            <div className="main-container">
                <section className="iq-resume wrapper">
                    <div className="container-fluid h-100">
                        <div className="row row-eq-height h-100">
                            <div className="col-md-3 purple-bg">
                                <div className="iq-objects">
                        <span className="iq-objects-01">
                            <img className="img-fluid" src="images/01.png" alt="drive" />
                        </span>
                                </div>
                            </div>
                            <div className="col-md-9 main-content align-self-center">
                                <div id="boxscroll" className="inner-box">
                                    <div className="resume-deta iq-plr-40">
                                        <div className="row white-bg">
                                            <div className="col-md-12">
                                                <div className="main-title">
                                                    <small>My Work Experience</small>
                                                    <h1 className="display-4 iq-tw-8 iq-font-purple">Resume</h1>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <h3 className="title iq-tw-8">Education</h3>
                                                <ul className="education">

                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">BSc, Computer Engineering
                                                            </div>
                                                            <div className="type iq-font-purple">Izmir Katip Celebi University</div>
                                                            <div className="info">I started to study Computer Engineering at Gediz University and move my student statuses to Izmir Katip Celebi University.

                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2019</span> <span>2011</span></span>
                                                    </li>

                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Computer Technologies & Programming
                                                            </div>
                                                            <div className="type iq-font-purple">Ege University</div>
                                                            <div className="info">I Started to learn so much thing about computer technologies and programming techniques.
                                                                It is my background of today's experiences.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2010</span> <span>2008</span></span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6">
                                                <h3 className="title iq-tw-8">Experience</h3>
                                                <ul className="education">
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Frontend Engineer</div>
                                                            <div className="type iq-font-purple">Optimum7 Inc.</div>
                                                            <div className="info">The company has a vision about future technologies and lots of experinces about e-commerce platforms suc as Big Commerce, Shopify, Volusion and Magento.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2020</span> <span>2019</span></span>
                                                    </li>
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Frontend Engineer
                                                            </div>
                                                            <div className="type iq-font-purple">Tripian Inc.</div>
                                                            <div className="info">I worked as a Frontend Developer on Tripian Inc. And also our team had developed Tripian web & mobile application for Airmiles Canada.
<br />
                                                                <b>Used technologies:</b>
                                                                ReactJS, Python, RestAPI, Amazon WS (serverless and amplify), Google Material Design Principles.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2019</span> <span>2019</span></span>
                                                    </li>
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Sr. Frontend
                                                                Developer
                                                            </div>
                                                            <div className="type iq-font-purple">KoçSistem Inc.</div>
                                                            <div className="info">I worked as a part of Ford Motor Company Development team. Our team was developing a brand new Ford Reseller web application.
<br />
                                                                <b>Used technologies:</b>
                                                                HTML, CSS, JavaScript, C# .net MVC, Oracle Database Systems.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2019</span> <span>2017</span></span>
                                                    </li>
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Sr. Frontend
                                                                Developer
                                                            </div>
                                                            <div className="type iq-font-purple">OSF Global Services Inc.</div>
                                                            <div className="info">
                                                                Salesforce Commerce Cloud E-commerce partner web apps development.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2017</span> <span>2017</span></span>
                                                    </li>
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Technical Lead
                                                            </div>
                                                            <div className="type iq-font-purple">E-Grafi Creative Web Agency.</div>
                                                            <div className="info">Manage a team and provide them useful web technologies to build awesome web apps & mobile apps.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2016</span> <span>2015</span></span>
                                                    </li>
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Sr. Web
                                                                Developer
                                                            </div>
                                                            <div className="type iq-font-purple">E-Grafi Creative Web Agency.</div>
                                                            <div className="info">
                                                                Develop brand-new web applications and start-up based applications and also develop commercial web sites.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2015</span> <span>2014</span></span>
                                                    </li>
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Web
                                                                Developer
                                                            </div>
                                                            <div className="type iq-font-purple">Promiks Promosyon Inc.</div>
                                                            <div className="info">
                                                                Building E-commerce platform to sell various products to their customers and business partners.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2013</span> <span>2011</span></span>
                                                    </li>
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Web
                                                                Developer
                                                            </div>
                                                            <div className="type iq-font-purple">Sivilis Consultancy Inc.</div>
                                                            <div className="info">
                                                                Building In-house web applications to make usable their employees.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2011</span> <span>2010</span></span>
                                                    </li>
                                                    <li><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Web Developer
                                                            </div>
                                                            <div className="type iq-font-purple">Freelance</div>
                                                            <div className="info">
                                                                Building project based websites and web applications for business partners.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2017</span> <span>2007</span></span>
                                                    </li>


                                                    <li className={"startup"}><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Co-Founder
                                                            </div>
                                                            <div className="type iq-font-purple">Kitapbu.org</div>
                                                            <div className="info">
                                                                Totally free book exchanging web platform. It was a non-profit start-up.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2018</span> <span>2013</span></span>
                                                    </li>

                                                    <li className={"startup"}><span></span>
                                                        <div>
                                                            <div className="lead iq-tw-6 iq-font-black">Co-Founder
                                                            </div>
                                                            <div className="type iq-font-purple">medicoexpo.com</div>
                                                            <div className="info">
                                                                Medico Expo is an exhibition platform for medical companies'.
                                                                Companies can search eachother and search products which are looking for in this website.
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="number"><span>2019</span> <span>2013</span></span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="interests-deta grey-bg iq-pall-40 iq-mtb-50">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 className="title iq-tw-8 iq-mt-0">My Interests</h3>
                                                <ul className="interests">
                                                    <li>
                                                        <i className="fa fa-music" aria-hidden="true"></i>
                                                        <h6>Music</h6>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-file-video-o" aria-hidden="true"></i>
                                                        <h6>Watch Movies</h6>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-picture-o" aria-hidden="true"></i>
                                                        <h6>Photography</h6>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-gamepad" aria-hidden="true"></i>
                                                        <h6>Gaming</h6>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-plane" aria-hidden="true"></i>
                                                        <h6>Traveling</h6>
                                                    </li>
                                                    <li>
                                                        <i className="fa fa-bicycle" aria-hidden="true"></i>
                                                        <h6>Bicycling</h6>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="skills iq-plr-40 iq-mt-80">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-md-6">
                                                <div className="skill-content">
                                                    <h3 className="title iq-tw-8 iq-mt-0">My Language skills</h3>

                                                </div>
                                                <div className="iq-progress-bar-linear">
                                                    <p className="iq-progress-bar-text iq-font-black">Turkish
                                                        <span>100/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="100"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear">
                                                    <p className="iq-progress-bar-text iq-font-black">English
                                                        <span>90/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="90"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black">
                                                    <p className="iq-progress-bar-text">Spanish
                                                        <span>40/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="40"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black">
                                                    <p className="iq-progress-bar-text">Russian
                                                        <span>35/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="35"></span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-lg-6 col-md-6 col-md-6">
                                                <div className="skill-content">
                                                    <h3 className="title iq-tw-8 iq-mt-0">My Technical skills</h3>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black">
                                                    <p className="iq-progress-bar-text">HTML
                                                        <span>98/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="98"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black">
                                                    <p className="iq-progress-bar-text">Css
                                                        <span>90/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="90"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black">
                                                    <p className="iq-progress-bar-text">React
                                                        <span>80/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="80"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black iq-mb-0">
                                                    <p className="iq-progress-bar-text">Javascript
                                                        <span>85/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="85"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black iq-mb-0">
                                                    <p className="iq-progress-bar-text">jQuery
                                                        <span>80/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="80"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black iq-mb-0">
                                                    <p className="iq-progress-bar-text">Angular
                                                        <span>50/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="50"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black iq-mb-0">
                                                    <p className="iq-progress-bar-text">NodeJS
                                                        <span>60/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="60"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black iq-mb-0">
                                                    <p className="iq-progress-bar-text">BigCommerce Development
                                                        <span>85/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="75"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black iq-mb-0">
                                                    <p className="iq-progress-bar-text">Shopify Development
                                                        <span>80/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="80"></span>
                                                    </div>
                                                </div>
                                                <div className="iq-progress-bar-linear iq-font-black iq-mb-0">
                                                    <p className="iq-progress-bar-text">Wordpress Development
                                                        <span>85/100</span>
                                                    </p>
                                                    <div className="iq-progress-bar">
                                                        <span data-percent="85"></span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default Resume;
